/* eslint-disable @typescript-eslint/no-explicit-any */
import { IGraphQLService } from '../../IGraphQLService';
import { ClientFetchResult, ClientQueryResult } from '../../types';
import { WebviewSharedGraphQLMethodsDependencies } from './types';
import { v4 as uuidv4 } from 'uuid';

let publisher: any = null;
let subscriber: any = null;
const result: any = { loading: true, data: null, error: null };

const sendMessage = async (
  type: 'query' | 'mutation',
  id: string,
  payload: any
) => {
  try {
    if (!publisher) {
      const EventService = (window as any).JWeb.Plugins.EventService;
      publisher = await EventService.createPublisher('graphql-hooks');
    }
    if (!publisher) {
      throw new Error('Unable to create or find JWeb EventService Publisher');
    }
    await publisher.publish('graphql', {
      type,
      payload: { ...payload, ID: id }
    });
  } catch (e) {
    console.error('Error in sendMessage:', e);
    throw e; // Rethrowing the error to be caught by query/mutate
  }
};

const receiveMessage = (event: any) => {
  if (event?.eventData?.ID && result[event.eventData.ID]) {
    result[event.eventData.ID] = {
      ...result[event.eventData.ID],
      loading: false,
      data: event.eventData.response.data
    };
  }
};

const createSubscriptions = async () => {
  if (!subscriber) {
    const EventService = (window as any).JWeb.Plugins.EventService;
    subscriber = await EventService.createSubscriber();
    await subscriber.subscribe(
      { eventName: 'graphqlResult', publisherId: 'root-webview2' },
      receiveMessage
    );
  }
};

export const createGraphQLMethodsForWindowsWebview = (
  _options: WebviewSharedGraphQLMethodsDependencies
): IGraphQLService => {
  console.log('Initialize GraphQLMethods For WindowsWebview');
  return {
    query: async <TData, TVariables>(
      _query: string,
      _variables?: TVariables
    ): Promise<ClientQueryResult<TData>> => {
      await createSubscriptions();
      const queryID = uuidv4();
      result[queryID] = { loading: true, data: null, error: null };
      await sendMessage('query', queryID, {
        query: _query,
        variables: _variables
      });

      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (!result[queryID].loading) {
            clearInterval(interval);
            resolve(result[queryID]);
          }
        }, 200); // Check every 200ms
      });
    },
    mutate: async <TData, TVariables>(
      _mutation: string,
      _variables?: TVariables
    ): Promise<ClientFetchResult<TData | undefined>> => {
      await createSubscriptions();
      const mutationID = uuidv4();
      result[mutationID] = { loading: true, data: null, error: null };
      await sendMessage('mutation', mutationID, {
        query: _mutation,
        variables: _variables
      });

      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (!result[mutationID].loading) {
            clearInterval(interval);
            resolve(result[mutationID]);
          }
        }, 200); // Check every 200ms
      });
    }
  };
};
